<template>
	<PromotionBannerKeysForm />
</template>

<script>
import PromotionBannerKeysForm from '@/components/PromotionBannerKeysForm.vue';

export default {
	name: 'PromotionBannerKeysCreate',

	components: {
		PromotionBannerKeysForm,
	},
};
</script>
